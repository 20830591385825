<template>
    <div v-if="subjectiveObjectiveData" class="border border-gray-100 bg-white p-2 rounded text-sm">
        <div class="flex items-center gap-2 mb-1.5">
            <span class="font-medium text-gray-700">Analiza articolului</span>
            <span class="text-gray-400 text-xs">•</span>
            <span class="text-gray-500 text-xs">Grad de obiectivitate</span>
        </div>
        <div class="flex flex-wrap gap-1.5">
            <span class="px-1.5 py-0.5 rounded bg-blue-100 text-blue-800 font-medium text-xs">
                {{ subjectiveObjectiveData.objective }}% Obiectiv
            </span>
            <span class="px-1.5 py-0.5 rounded bg-red-100 text-red-800 font-medium text-xs">
                {{ subjectiveObjectiveData.subjective }}% Subiectiv
            </span>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        subjectiveObjectiveData: {
            type: Object,
            required: true,
        },
    },
};
</script>
