<script setup>
import Navigation from "./Navigation.vue";
import Footer from "./Footer.vue";
</script>

<template>
    <div class="flex flex-col min-h-screen">
        <Navigation></Navigation>
        <main class="pt-20">
            <slot />
        </main>
        <Footer></Footer>
    </div>
</template>
