<template>
    <div class="articles-container space-y-4">
        <Suspense>
            <LazyArticleCard
                v-for="articleId in articleIds"
                :key="articleId"
                :article-id="articleId"
            />
            <template #fallback>
                <div class="space-y-4">
                    <ArticleCardSkeleton v-for="i in 5" :key="i" />
                </div>
            </template>
        </Suspense>
    </div>
</template>

<script>
import LazyArticleCard from './LazyArticleCard.vue';
import ArticleCardSkeleton from './ArticleCardSkeleton.vue';

export default {
    name: 'LazyArticlesList',
    components: {
        LazyArticleCard,
        ArticleCardSkeleton
    },
    props: {
        articles: {
            type: Object,
            required: true,
        },
    },
    computed: {
        articleIds() {
            return this.articles.data || [];
        }
    }
};
</script>
