<template>
    <div class="max-w-7xl mx-auto md:px-4 py-6">
        <!-- Header Section -->
        <div class="px-2">
            <!-- Google AdSense -->
            <div class="flex flex-wrap gap-4 md:gap-6 justify-between items-center md:w-2/3">
                <!-- Publication Info -->
                <div class="flex flex-wrap items-center gap-2 text-xs text-gray-600">
                    <img
                        v-if="article.publication"
                        :src="article.publication.logo"
                        :alt="article.publication.name"
                        class="h-6 w-auto"
                    />
                    <span>Publicat acum {{ article.publishedAgo }}</span>
                </div>

                <!-- Sharing Section -->
                <div class="flex flex-wrap gap-1 items-center">
                    <p class="text-xs font-medium text-gray-700">Distribuie</p>
                    <a
                        @click="visitFbUrl(articleUrl)"
                        target="_blank"
                        class="flex items-center gap-2 px-3 py-1 hover:scale-105 transition transform text-xs bg-blue-700 text-white rounded-md hover:bg-blue-800">
                        <i class="fab fa-facebook"></i>
                    </a>
                    <a
                        :href="whatsappShareUrl"
                        target="_blank"
                        class="flex items-center gap-2 px-3 py-1 hover:scale-105 transition transform text-xs bg-lime-600 text-white rounded-md hover:bg-lime-500">
                        <i class="fab fa-whatsapp"></i>
                    </a>
                </div>
            </div>
            <!-- Title and Image -->
            <div class="flex flex-col md:flex-row gap-2 mb-2 max-md:max-h-[650px]">
                <div class="flex-1">
                    <div class="py-0.5 my-0.5 rounded-3xl bg-gradient-to-r from-main to-main-blue mb-3"></div>
                    <h1 class="text-2xl max-md:text-xl font-bold">{{ article.title }}</h1>
                    <!-- Social Media Share Buttons -->
                    <SummaryDisplay :center-summary="article.centerSummary" :summary-text="article.summaryText"
                                    :left-summary="article.leftSummary"
                                    :right-summary="article.rightSummary"></SummaryDisplay>
                </div>
                <div class="md:w-1/3 max-md:hidden">
                    <img
                        v-if="article.image"
                        :src="article.image"
                        :alt="article.title"
                        class="w-full h-auto rounded-lg"
                    />
                </div>
            </div>
            <amp-ad width="100vw" height="320"
                    type="adsense"
                    data-ad-client="ca-pub-4069186346097674"
                    data-ad-slot="5224335733"
                    data-auto-format="rspv"
                    data-full-width="">
                <div overflow=""></div>
            </amp-ad>
            <!-- Analysis Section -->
            <div class="grid grid-cols-1 md:grid-cols-2 gap-2">
                <div class="space-y-1 max-h-[400px]">
                    <div class="inline-flex gap-x-2 justify-between max-md:flex-col max-md:space-y-1">
                        <MentionedParties :party-tags="article.partyTags"/>
                        <PoliticalOrientation :political-leaning="article.politicalLeaningData"/>
                    </div>
                    <SubjectiveObjectiveLeaning :subjective-objective-data="article.subjective_objective_leaning"/>
                    <PublicationOwner :publication="article.publication"/>
                </div>
                <MentionedPeople :tags="article.personTags"/>
            </div>
            <div class="space-y-1 text-xs w-2/3 mt-1">
                <div class="border border-gray-100 bg-white p-2 rounded">
                    <div class="flex items-center gap-2">
                        <a :href="article.url"
                           class="text-gray-800 cursor-pointer hover:text-blue-600 font-medium inline-flex items-center gap-1"
                           target="_blank">
                            Vezi sursa
                            <i class="fas fa-external-link-alt fa-xs"></i>
                        </a>
                    </div>
                </div>
            </div>

            <ArticleTabs v-if="!similarArticleLoading" :similar-articles="similarArticles"></ArticleTabs>
            <LoadingSpinner v-else></LoadingSpinner>
        </div>
    </div>
</template>

<script>
import MentionedParties from "../Components/MentionedParties.vue";
import PoliticalOrientation from "../Components/PoliticalOrientation.vue";
import SubjectiveObjectiveLeaning from "../Components/SubjectiveObjectiveLeaning.vue";
import PublicationOwner from "../Components/PublicationOwner.vue";
import MentionedPeople from "../Components/MentionedPeople.vue";
import SummaryDisplay from "../Components/SummaryDisplay.vue";
import ArticleTabs from "./ArticleTabs.vue";
import axios from "axios";
import LoadingSpinner from "../Components/LoadingSpinner.vue";

export default {
    props: {
        article: Object,
        seo: Object,
    },
    data() {
        return {
            selectedSummary: "all",
            similarArticleLoading: false,
            similarArticles: {
                all: [],
                left: [],
                center: [],
                right: [],
            },
        };
    },
    computed: {
        articleUrl() {
            return route('article', this.article.slug);
        },
        whatsappShareUrl() {
            return `https://api.whatsapp.com/send?text=${encodeURIComponent(this.article.title)}%20${encodeURIComponent(this.articleUrl)}`;
        }
    },
    methods: {
        async fetchData() {
            try {
                this.similarArticleLoading = true;
                const [similarArticles] = await Promise.all([
                    axios.get(`/api/article/${this.article.id}/similar`),
                ]);

                let data = similarArticles.data;
                if (data.stanga) {
                    data.left = Object.values(data.stanga);
                }
                if (data.dreapta) {
                    data.right = Object.values(data.dreapta);
                }
                if (data.centru) {
                    data.center = Object.values(data.centru);
                }
                this.similarArticles = similarArticles.data;
                this.similarArticles.left = data.left;
                this.similarArticles.center = data.center;
                this.similarArticles.right = data.right;
            } catch (error) {
                console.error("Failed to fetch charts data:", error);
            } finally {
                this.similarArticleLoading = false;
            }
        },
        loadFacebookSDK() {
            if (window.FB) {
                FB.init({
                    appId: '956174368903216', // Replace with your Facebook App ID
                    xfbml: true,
                    version: 'v21.0',
                });
            } else {
                // Dynamically load the Facebook SDK if not loaded
                window.fbAsyncInit = () => {
                    FB.init({
                        appId: '956174368903216', // Replace with your Facebook App ID
                        xfbml: true,
                        version: 'v21.0',
                    });
                    this.$nextTick(() => {
                        FB.XFBML.parse();
                    });
                };
                const script = document.createElement('script');
                script.src = "https://connect.facebook.net/en_US/sdk.js";
                script.async = true;
                script.defer = true;
                script.crossorigin = "anonymous";
                document.body.appendChild(script);
            }
        },
        visitFbUrl(url) {
            FB.ui({
                method: 'share',
                href: url
            }, function(response){});
        },
        trackArticleView() {
            if (window.gtag) {
                window.gtag('event', 'article_view', {
                    'event_category': 'Article',
                    'event_label': this.article.title,
                    'article_id': this.article.id,
                    'non_interaction': false
                });
            }
        }
    },
    components: {
        LoadingSpinner,
        ArticleTabs,
        SummaryDisplay,
        MentionedPeople,
        MentionedParties,
        PoliticalOrientation,
        SubjectiveObjectiveLeaning,
        PublicationOwner,
    },
    mounted() {
        this.loadFacebookSDK();
        this.fetchData();
        this.trackArticleView();
    }
};
</script>

