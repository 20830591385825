<script setup>
import Checkbox from '@/Components/Checkbox.vue';
import InputError from '@/Components/InputError.vue';
import InputLabel from '@/Components/InputLabel.vue';
import PrimaryButton from '@/Components/PrimaryButton.vue';
import TextInput from '@/Components/TextInput.vue';
import {Head, Link, useForm} from '@inertiajs/vue3';

// Social login routes
const googleLoginRoute = route('social-login', {provider: 'google'});
const facebookLoginRoute = route('social-login', {provider: 'facebook'});

defineProps({
    canResetPassword: {
        type: Boolean,
    },
    status: {
        type: String,
    },
});

const form = useForm({
    email: '',
    password: '',
    remember: false,
});

const submit = () => {
    form.post(route('login'), {
        onFinish: () => form.reset('password'),
    });
};
</script>

<template>
    <div class="py-10 flex items-center justify-center">
        <div class="w-full max-w-4xl px-4 sm:px-6 lg:px-8">
            <form
                @submit.prevent="submit"
                class="prose-sm bg-white p-6 sm:p-8 lg:p-10 rounded-xl shadow-lg max-w-md mx-auto grid gap-6"
            >
                <h1 class="text-center text-2xl font-extrabold text-main">Autentificare</h1>

                <div v-if="status" class="mb-4 text-sm font-medium text-green-600">
                    {{ status }}
                </div>

                <!-- Email -->
                <div class="grid gap-2">
                    <InputLabel for="email" value="Adresă de email"/>
                    <TextInput
                        id="email"
                        type="email"
                        class="mt-1 block w-full border-gray-300 rounded-md focus:border-main focus:ring-main"
                        v-model="form.email"
                        required
                        autofocus
                        autocomplete="username"
                    />
                    <InputError class="error mt-1" :message="form.errors.email"/>
                </div>

                <!-- Password -->
                <div class="grid gap-2">
                    <InputLabel for="password" value="Parolă"/>
                    <TextInput
                        id="password"
                        type="password"
                        class="mt-1 block w-full border-gray-300 rounded-md focus:border-main focus:ring-main"
                        v-model="form.password"
                        required
                        autocomplete="current-password"
                    />
                    <InputError class="error mt-1" :message="form.errors.password"/>
                </div>

                <!-- Remember me -->
                <div class="block">
                    <label class="flex items-center">
                        <Checkbox name="remember" v-model:checked="form.remember"/>
                        <span class="ms-2 text-sm text-gray-600">Ține-mă minte</span>
                    </label>
                </div>

                <!-- Forgot password and login button -->
                <div class="flex flex-col-reverse sm:flex-row sm:justify-between items-center mt-4">
                    <Link
                        v-if="canResetPassword"
                        :href="route('password.request')"
                        class="text-sm font-medium text-main hover:underline mt-4 sm:mt-0"
                    >
                        Ai uitat parola?
                    </Link>

                    <PrimaryButton
                        class="bg-main text-white hover:scale-105 hover:bg-main/90 px-4 py-2 rounded-md transition ease-in-out duration-200"
                        :disabled="form.processing"
                    >
                        Autentificare
                    </PrimaryButton>
                </div>

                <!-- Separator -->
                <div class="flex items-center gap-2 mt-6">
                    <div class="flex-grow border-t border-gray-300"></div>
                    <span class="text-sm text-gray-500">sau autentifică-te cu</span>
                    <div class="flex-grow border-t border-gray-300"></div>
                </div>

                <!-- Social Login Buttons -->
                <div class="grid gap-4 mt-4">
                    <a
                        :href="googleLoginRoute"
                        class="bg-white border border-gray-300 text-main/90 hover:bg-main/10 rounded-md px-4 py-2 flex items-center justify-center gap-2 transition"
                    >
                        <img
                            src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg"
                            alt="Google logo"
                            class="w-5 h-5"
                        />
                        <span>Conectează-te cu Google</span>
                    </a>

<!--                    <a-->
<!--                        :href="facebookLoginRoute"-->
<!--                        class="bg-blue-600 text-white hover:bg-blue-700 rounded-md px-4 py-2 flex items-center justify-center gap-2 transition"-->
<!--                    >-->
<!--                        <img-->
<!--                            src="https://upload.wikimedia.org/wikipedia/commons/5/51/Facebook_f_logo_%282019%29.svg"-->
<!--                            alt="Facebook logo"-->
<!--                            class="w-5 h-5"-->
<!--                        />-->
<!--                        <span>Conectează-te cu Facebook</span>-->
<!--                    </a>-->
                </div>

                <!-- Register Link -->
                <div class="text-center mt-6">
                    <span class="text-sm text-gray-600">Nu ai un cont?</span>
                    <Link href="/register" class="text-sm font-medium text-main hover:underline">
                        Înregistrează-te
                    </Link>
                </div>
            </form>
        </div>
    </div>
</template>
