<template>
    <div class="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <!-- Header Section -->
        <div class="mb-8">
            <h1 class="text-3xl font-bold text-gray-900 mb-4">Metodologia Noastră</h1>
            <p class="text-lg text-gray-700">
                Descoperă cum folosim tehnologia și analiza de date pentru a oferi o perspectivă obiectivă asupra spectrului politic.
            </p>
        </div>

        <!-- Main Content Grid -->
        <div class="flex flex-wrap gap-8">
            <!-- Left Column - Technical Details -->
            <div class="space-y-6">
                <AboutCard title="Colectarea Datelor">
                    <p class="text-gray-700">
                        Procesul nostru începe cu colectarea sistematică a datelor din surse oficiale și mass-media.
                        Utilizăm tehnologii automatizate pentru a agrega:
                    </p>
                    <ul class="list-disc list-inside space-y-2 text-gray-700 mt-4">
                        <li>Articole politice din presa locală și națională</li>
                    </ul>
                </AboutCard>

                <AboutCard title="Procesarea și Analiza">
                    <p class="text-gray-700 mb-4">
                        Folosim tehnologii avansate de AI pentru a procesa și analiza conținutul politic:
                    </p>
                    <ul class="list-disc list-inside space-y-2 text-gray-700">
                        <li>Analiza sentimentului și tonului declarațiilor</li>
                        <li>Identificarea articolelor de știri similare</li>
                        <li>Identificarea promisiunilor și angajamentelor</li>
                        <li>Evaluarea obiectivității conținutului</li>
                        <li>Clasificarea orientării politice</li>
                    </ul>
                </AboutCard>
                <AboutCard title="Tehnologii Utilizate">
                    <div class="space-y-4">
                        <p class="text-gray-700">
                            Platforma noastră utilizează un ecosistem complex de tehnologii moderne:
                        </p>
                        <ul class="space-y-4 text-gray-700">
                            <li class="flex gap-3">
                                <svg class="w-6 h-6 text-main flex-shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                                <span>
                                    <strong class="font-medium">Inteligență Artificială</strong> - Algoritmi avansați de procesare a limbajului natural
                                </span>
                            </li>
                            <li class="flex gap-3">
                                <svg class="w-6 h-6 text-main flex-shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                                <span>
                                    <strong class="font-medium">Big Data</strong> - Procesare și analiză de date la scară mare
                                </span>
                            </li>
                            <li class="flex gap-3">
                                <svg class="w-6 h-6 text-main flex-shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                                <span>
                                    <strong class="font-medium">Machine Learning</strong> - Modele de învățare pentru clasificare și analiză
                                </span>
                            </li>
                        </ul>
                    </div>
                </AboutCard>

                <AboutCard title="Actualizări și Mentenanță">
                    <p class="text-gray-700">
                        Sistemul nostru este actualizat constant pentru a reflecta cele mai recente dezvoltări:
                    </p>
                    <ul class="list-disc list-inside space-y-2 text-gray-700 mt-4">
                        <li>Actualizări zilnice ale bazei de date</li>
                        <li>Îmbunătățiri continue ale algoritmilor</li>
                        <li>Monitorizare activă a acurateței</li>
                    </ul>
                </AboutCard>

                <!-- Warning Card -->
                <div class="bg-amber-50 border-l-4 border-amber-400 rounded-xl p-6 shadow-md">
                    <p class="text-amber-800 text-sm">
                        <strong class="font-medium">Important:</strong>
                        Deși folosim tehnologii avansate și procese riguroase de verificare, vă încurajăm să:
                    </p>
                    <ul class="list-disc list-inside mt-2 space-y-1">
                        <li>Verificați informațiile din surse multiple</li>
                        <li>Consultați documentele oficiale ale partidelor</li>
                        <li>Formați-vă propria opinie informată</li>
                    </ul>
                </div>
            </div>
            <!-- Right Column - Process Steps -->
            <div class="space-y-6">
                <AboutCard title="Articole">

                    <p class="text-gray-700">
                        Informațiile procesate sunt prezentate într-un format ușor de înțeles pentru
                        a se intelege mai bine discursul politic:
                    </p>

                    <!-- Grad de Obiectivitate -->
                    <div class="mt-4">
                        <h4 class="font-semibold text-gray-800">Grad de Obiectivitate</h4>
                        <p class="text-gray-700">
                            <strong>Descriere:</strong> Măsoară nivelul de obiectivitate și subiectivitate din articol.
                            O evaluare ridicată de obiectivitate indică informații mai neutre și bazate pe fapte, în timp ce o evaluare de subiectivitate sugerează
                            opinii sau perspective personale.
                        </p>
                    </div>

                    <!-- Orientare Politică -->
                    <div class="mt-4">
                        <h4 class="font-semibold text-gray-800">Orientare Politică</h4>
                        <p class="text-gray-700">
                            <strong>Descriere:</strong> Identifică tendința politică generală a articolului, cum ar fi Dreapta, Stânga sau Centru.
                            Aceasta reflectă direcția ideologică sau poziția exprimată în articol sau a sursei.
                        </p>
                    </div>

                    <!-- Proprietate Afiliere -->
                    <div class="mt-4">
                        <h4 class="font-semibold text-gray-800">Proprietate / Afiliere</h4>
                        <p class="text-gray-700">
                            <strong>Descriere:</strong> Definește tipul de proprietate al sursei de știri sau al publicației, în funcție de afilierea sa:
                        </p>
                        <ul class="list-disc list-inside text-gray-700 ml-4 space-y-1">
                            <li><strong>Media Conglomerate:</strong> Deținută de un grup media mare.</li>
                            <li><strong>Individual:</strong> Deținută de un individ (e.g., Cristian Burci).</li>
                            <li><strong>Government:</strong> Deținută sau finanțată de guvern.</li>
                            <li><strong>Independent:</strong> Deținută independent, fără influențe externe semnificative.</li>
                            <li><strong>Telecom:</strong> Deținută de o companie de telecomunicații.</li>
                            <li><strong>Corporation:</strong> Deținută de o corporație non-media.</li>
                        </ul>
                    </div>

                    <!-- Partide Menționate -->
                    <div class="mt-4">
                        <h4 class="font-semibold text-gray-800">Partide Menționate</h4>
                        <p class="text-gray-700">
                            <strong>Descriere:</strong> Prezintă partidele politice menționate în articol, ajutând la identificarea
                            spectrului politic discutat sau influențat.
                        </p>
                    </div>

                    <!-- Persoane Menționate -->
                    <div class="mt-4">
                        <h4 class="font-semibold text-gray-800">Persoane Menționate</h4>
                        <p class="text-gray-700">
                            <strong>Descriere:</strong> Lista persoanelor menționate în articol, incluzând politicieni, candidați,
                            sau alte figuri relevante pentru a oferi un context mai clar asupra conținutului.
                        </p>
                    </div>

                    <!-- Articole Similare -->
                    <div class="mt-4">
                        <h4 class="font-semibold text-gray-800">Nr Articole Similare</h4>
                        <p class="text-gray-700">
                            <strong>Descriere:</strong> O selecție de articole similare care permit utilizatorilor să exploreze subiecte
                            sau perspective legate de conținutul analizat.
                        </p>
                    </div>
                </AboutCard>
                <AboutCard title="Publicații">
                    <p class="text-gray-700">
                        Informații despre publicațiile analizate, incluzând frecvența, orientarea politică, și subiectele dominante
                        pentru a oferi o privire de ansamblu asupra surselor de știri.
                    </p>

                    <!-- Total Articole -->
                    <div class="mt-6">
                        <h3 class="font-semibold text-lg text-gray-800">Total Articole</h3>
                        <p class="text-gray-700">
                            <strong>Descriere:</strong> Numărul total de articole publicate de publicație în perioada analizată.
                            Aceasta oferă o perspectivă asupra volumului și activității sursei.
                        </p>
                    </div>

                    <!-- Frecvența Publicării -->
                    <div class="mt-6">
                        <h3 class="font-semibold text-lg text-gray-800">Frecvența Publicării</h3>
                        <p class="text-gray-700">
                            <strong>Descriere:</strong> Frecvența cu care publicația publică articole (zilnic, săptămânal, lunar).
                            Aceasta arată cât de activă este sursa și cât de des sunt publicate informațiile.
                        </p>
                    </div>

                    <!-- Cele mai Menționate Partide -->
                    <div class="mt-6">
                        <h3 class="font-semibold text-lg text-gray-800">Cele mai Menționate Partide</h3>
                        <p class="text-gray-700">
                            <strong>Descriere:</strong> Partidele politice care apar cel mai des în articolele publicației, indicând
                            direcțiile de interes sau biasurile posibile în raportarea publicației.
                        </p>
                    </div>

                    <!-- Cele mai Menționate Persoane -->
                    <div class="mt-6">
                        <h3 class="font-semibold text-lg text-gray-800">Cele mai Menționate Persoane</h3>
                        <p class="text-gray-700">
                            <strong>Descriere:</strong> Persoanele care apar cel mai des în articolele publicației,
                            de obicei politicieni sau figuri publice. Aceasta poate indica o focalizare asupra anumitor actori politici.
                        </p>
                    </div>

                    <!-- Orientarea Politică a Articolelor -->
                    <div class="mt-6">
                        <h3 class="font-semibold text-lg text-gray-800">Orientarea Politică a Articolelor</h3>
                        <p class="text-gray-700">
                            <strong>Descriere:</strong> Identifică tendința politică generală a articolelor publicate de sursă:
                            Stânga, Dreapta, sau Centru. Aceasta ajută la înțelegerea biasului potențial al publicației.
                        </p>
                    </div>

                    <!-- Obiectiv vs. Subiectiv -->
                    <div class="mt-6">
                        <h3 class="font-semibold text-lg text-gray-800">Obiectiv vs. Subiectiv</h3>
                        <p class="text-gray-700">
                            <strong>Descriere:</strong> Raportul dintre articolele obiective și cele subiective publicate de sursă.
                            Articolele obiective se bazează pe fapte și date concrete, în timp ce cele subiective prezintă opinii sau comentarii.
                        </p>
                    </div>
                </AboutCard>
                <AboutCard title="Politician">
                    <p class="text-gray-700">
                        Informații despre politicianul analizat, incluzând sursele, distribuția sentimentelor și cuvintele sau entitățile frecvent menționate.
                    </p>

                    <!-- Cele mai Frecvente Surse -->
                    <div class="mt-6">
                        <h3 class="font-semibold text-lg text-gray-800">Cele mai Frecvente Surse</h3>
                        <p class="text-gray-700">
                            <strong>Descriere:</strong> Lista surselor de știri care menționează frecvent acest politician, indicând
                            sursele cu cea mai mare acoperire a activităților și declarațiilor sale.
                        </p>
                    </div>

                    <!-- Distribuție Sentiment -->
                    <div class="mt-6">
                        <h3 class="font-semibold text-lg text-gray-800">Distribuție Sentiment</h3>
                        <p class="text-gray-700">
                            <strong>Descriere:</strong> Analiza sentimentului general al articolelor care menționează politicianul,
                            împărțită în categorii precum pozitiv, negativ și neutru, pentru a oferi o perspectivă asupra percepției publice.
                        </p>
                    </div>

                    <!-- Cele mai Comune Cuvinte -->
                    <div class="mt-6">
                        <h3 class="font-semibold text-lg text-gray-800">Cele mai Comune Cuvinte</h3>
                        <p class="text-gray-700">
                            <strong>Descriere:</strong> O analiză a celor mai frecvente cuvinte utilizate în articolele sau declarațiile asociate
                            politicianului, indicând teme recurente sau subiecte de interes.
                        </p>
                    </div>

                    <!-- Cele mai Menționate Entități -->
                    <div class="mt-6">
                        <h3 class="font-semibold text-lg text-gray-800">Cele mai Menționate Entități</h3>
                        <p class="text-gray-700">
                            <strong>Descriere:</strong> O listă a entităților (persoane, organizații, locații) care sunt frecvent menționate
                            în contextul discuțiilor despre politician, oferind o perspectivă asupra conexiunilor și subiectelor relevante.
                        </p>
                    </div>

                    <!-- Afirmatii -->
                    <div class="mt-6">
                        <h3 class="font-semibold text-lg text-gray-800">Afirmatii</h3>
                        <p class="text-gray-700">
                            <strong>Descriere:</strong> Declarațiile și afirmațiile importante ale politicianului, analizate pentru a înțelege
                            tonul, frecvența și contextul în care acestea sunt menționate.
                        </p>

                        <!-- Analiza fiecărei afirmații -->
                        <div class="mt-4 ml-4">
                            <!-- Analiza Sentimentala -->
                            <div class="mt-2">
                                <h5 class="font-semibold text-gray-800">Analiza Sentimentală</h5>
                                <p class="text-gray-700">
                                    <strong>Descriere:</strong> Evaluarea sentimentului asociat fiecărei afirmații pentru a determina
                                    dacă tonul este pozitiv, negativ sau neutru.
                                </p>
                            </div>

                            <!-- Cele mai Folosite Cuvinte -->
                            <div class="mt-2">
                                <h5 class="font-semibold text-gray-800">Cele mai Folosite Cuvinte</h5>
                                <p class="text-gray-700">
                                    <strong>Descriere:</strong> O analiză a cuvintelor predominante folosite în afirmații pentru a identifica
                                    subiectele centrale și mesajele transmise de politician.
                                </p>
                            </div>

                            <!-- Entități Menționate -->
                            <div class="mt-2">
                                <h5 class="font-semibold text-gray-800">Entități Menționate</h5>
                                <p class="text-gray-700">
                                    <strong>Descriere:</strong> Entitățile (persoane, organizații, evenimente) care apar în contextul
                                    fiecărei afirmații, indicând temele discutate și potențialele conexiuni.
                                </p>
                            </div>
                        </div>
                    </div>
                </AboutCard>
            </div>

        </div>
    </div>
</template>

<script>
import { defineComponent } from "vue";
import AboutCard from "../Components/AboutCard.vue";
import { Link } from "@inertiajs/vue3";

export default defineComponent({
    components: {
        Link,
        AboutCard,
    },
});
</script>

<style scoped>
/* Add custom styles here if needed */
</style>
