<template>
    <div class="flex items-center gap-1.5" @click.stop.prevent>
        <!-- Badge with ownership type -->
        <div :class="`inline-flex items-center px-1.5 py-0.5 text-xs text-white rounded ${backgroundColor}`">
            <span class="capitalize">{{ translatedType }}</span>
        </div>

        <!-- Separator -->
        <span v-if="ownerDetails" class="text-gray-400">•</span>

        <!-- Owner name -->
        <span v-if="ownerDetails" class="text-xs text-gray-600 font-medium">
            {{ ownerDetails.name }}
        </span>
    </div>
</template>

<script>
export default {
    props: {
        publication: {
            type: Object,
            required: true,
        },
    },
    computed: {
        ownershipType() {
            return this.publication?.ownership_type ?? "default";
        },
        backgroundColor() {
            const colors = {
                media_conglomerate: "bg-blue-500",
                individual: "bg-gray-800",
                government: "bg-red-500",
                independent: "bg-gray-500",
                telecom: "bg-purple-500",
                corporation: "bg-yellow-500",
                default: "bg-gray-300",
            };
            return colors[this.ownershipType] || colors.default;
        },
        translatedType() {
            const translations = {
                media_conglomerate: this.$t("ownership.types.media_conglomerate"),
                individual: this.$t("ownership.types.individual"),
                government: this.$t("ownership.types.government"),
                independent: this.$t("ownership.types.independent"),
                telecom: this.$t("ownership.types.telecom"),
                corporation: this.$t("ownership.types.corporation"),
                default: this.$t("ownership.types.default"),
            };
            return translations[this.ownershipType] || translations.default;
        },
        ownerDetails() {
            if (!this.publication?.owner) return null;

            const icons = {
                media_conglomerate: "fa-building",
                individual: "fa-user",
                government: "fa-landmark",
                corporation: "fa-building-columns",
                telecom: "fa-tower-broadcast",
            };

            return {
                name: this.publication.owner.name,
                icon: icons[this.ownershipType] || "fa-circle",
            };
        },
    },
};
</script>
