<template>
    <div class="relative mb-4">
        <div class="flex items-center bg-white rounded-xl shadow-sm border border-gray-200 hover:border-main transition-all duration-200 group">
            <!-- Search Icon -->
            <div class="pl-4">
                <svg
                    class="w-5 h-5 text-gray-400 group-hover:text-main transition-colors duration-200"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                >
                    <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                    />
                </svg>
            </div>

            <!-- Search Input -->
            <input
                v-model="searchQuery"
                @keydown.enter="search"
                type="search"
                :placeholder="placeholder"
                class="flex-1 w-full px-3 py-3 border-0 text-sm md:text-base focus:ring-0 text-gray-800 placeholder-gray-400 bg-transparent"
            />

            <!-- Clear Button -->
            <button
                v-if="searchQuery"
                @click="clearSearch"
                class="px-2 text-gray-400 hover:text-gray-600 transition-colors duration-200"
                aria-label="Clear search"
            >
                <svg
                    class="w-5 h-5"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                >
                    <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M6 18L18 6M6 6l12 12"
                    />
                </svg>
            </button>

            <!-- Loading Spinner -->
            <div v-if="loading" class="px-3">
                <div class="animate-spin rounded-full h-5 w-5 border-2 border-gray-300 border-t-main"></div>
            </div>

            <!-- Filter Toggle -->
            <button
                aria-label="Arată Filtre"
                @click="$emit('toggleFilters')"
                class="px-4 border-x border-gray-200 hover:bg-gray-50 transition-all duration-200"
                :class="{ 'text-main bg-surface rounded-lg py-2': filtersVisible, 'text-gray-400': !filtersVisible }"
            >
                <svg
                    class="w-5 h-5"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                >
                    <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M3 4a1 1 0 011-1h16a1 1 0 011 1v2a1 1 0 01-.293.707L13 13v7a1 1 0 01-.293.707l-2 2A1 1 0 019 23V13L3.293 6.707A1 1 0 013 6V4z"
                    />
                </svg>
            </button>

            <!-- Search Button -->
            <button
                @click="search"
                class="px-6 py-3 bg-main text-white rounded-r-xl hover:scale-105 transition-all duration-200 flex items-center gap-2"
            >
                <span class="hidden sm:inline">Caută</span>
                <svg
                    class="w-5 h-5 sm:hidden"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                >
                    <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                    />
                </svg>
            </button>
        </div>

        <!-- Search Suggestions (optional) -->
<!--        <div v-if="searchQuery && !loading" class="absolute w-full mt-1 bg-white rounded-lg shadow-lg border border-gray-200 py-2 z-10">-->
<!--            <div class="px-3 py-1 text-xs text-gray-500">Sugestii de căutare</div>-->
<!--            <div class="max-h-48 overflow-y-auto">-->
<!--                <div class="px-3 py-1 text-xs text-gray-500">Sugestii de căutare</div>-->
<!--                <div class="px-3 py-1 text-xs text-gray-500">Sugestii de căutare</div>-->
<!--                <div class="px-3 py-1 text-xs text-gray-500">Sugestii de căutare</div>-->
<!--            </div>-->
<!--        </div>-->
    </div>
</template>

<script>
export default {
    props: {
        placeholder: {
            type: String,
            default: "Caută articole, știri, analize..."
        },
        filtersVisible: {
            type: Boolean,
            default: false
        },
        searchField: {
            type: String,
            default: ""
        }
    },

    data() {
        return {
            searchQuery: "",
            loading: false
        }
    },

    methods: {
        search() {
            if (!this.searchQuery.trim()) return
            this.updateURLParams()
            this.$emit("search", this.searchQuery)
        },

        clearSearch() {
            this.searchQuery = ""
            this.updateURLParams()
            this.$emit("search", "")
        },

        updateURLParams() {
            const params = new URLSearchParams(window.location.search)

            if (this.searchQuery) {
                params.set("search", this.searchQuery)
            } else {
                params.delete("search")
            }

            if (this.filtersVisible) {
                params.set("filtersVisible", "true")
            } else {
                params.delete("filtersVisible")
            }

            window.history.replaceState({}, "", `${window.location.pathname}?${params.toString()}`)
        },

        loadFiltersFromURL() {
            const params = new URLSearchParams(window.location.search)

            if (params.has("search")) {
                this.searchQuery = params.get("search")
            }

            if (params.has("filtersVisible")) {
                this.$emit("toggleFilters", params.get("filtersVisible") === "true")
            }
        }
    },

    created() {
        this.loadFiltersFromURL()
    }
}
</script>
