<template>
    <div v-if="partyTags.length > 0"
         class="border border-gray-100 bg-white px-2 py-1 rounded inline-flex items-center gap-2 min-h-[30px]">
        <span class="font-medium text-gray-700 text-sm">Partide</span>
        <span class="text-gray-400 text-xs">•</span>
        <span class="text-gray-500 text-xs text-nowrap">{{ partyTags.length }} menționate</span>

        <div class="flex flex-wrap gap-1.5">
            <div
                v-for="tag in partyTags"
                :key="tag.name"
                class="w-5 h-5 relative group cursor-pointer"
                @click="$emit('partyClicked', { party: tag.name })"
            >
                <LazyImage :src="tag.logo_url" :alt="tag.name" height="20px" width="20px" class-styles="w-full h-[20px] object-contain"></LazyImage>
                <div
                    class="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-1 px-1.5 py-0.5 bg-gray-800 text-white rounded opacity-0 group-hover:opacity-100 whitespace-nowrap transition-opacity z-10"
                >
                    {{ tag.name }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import LazyImage from "@/Components/LazyImage.vue";

export default {
    components: {LazyImage},
    props: {
        partyTags: {
            type: Object,
            default: () => [],
        },
    },
};
</script>
