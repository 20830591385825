<template>
    <div :id="'article-' + article.id" class="space-y-4 mt-4">
        <div
            class="flex flex-col sm:flex-row bg-white hover:shadow-xl  border border-b-0 border-l-0 border-r-0 border-main/10 border- rounded-lg overflow-hidden shadow-md transition-colors duration-200"
        >
            <!-- Card Content -->
            <div class="flex-1 p-4">
                <!-- Header -->
                <div class="flex flex-wrap items-center mb-2 gap-x-2 text-xs text-gray-600">
                    <div v-if="article.publication.logo" class="flex items-center">
                        <LazyImage :src="article.publication.logo"
                                     :alt="article.publication.name" class-styles="!w-auto !h-[25px]" height="25px" width="25px"></LazyImage>
                    </div>
                    <div class="flex flex-wrap items-center gap-x-2 h-[25px]">
                        <span>Publicat acum {{ article.publishedAgo }}</span>
                        <div v-if="article.updatedAgo" class="flex items-center gap-x-2">
                            <span class="hidden lg:inline-block">•</span>
                            <span>Actualizat acum {{ article.updatedAgo }}</span>
                        </div>
                    </div>
                </div>

                <!-- Title and Summary -->
                <Link
                    :href="`/articol/${article.slug}`"
                    class="block group"
                    :aria-label="article.title"
                >
                    <!-- Mobile Image -->
                    <div
                        v-if="article.image"
                        class="md:hidden h-40 w-full mb-3 overflow-hidden rounded"
                    >
                        <LazyImage :src="article.image" :alt="article.title" height="200" width="200" ></LazyImage>
                    </div>

                    <h2 class="font-semibold sm:text-xl mb-2 line-clamp-2">
                        <div class="h-0.5 rounded-xl bg-gradient-to-r from-main to-main-blue mb-1"></div>
                        {{ article.title }}
                    </h2>
                    <p class="text-gray-700 text-sm line-clamp-3 mb-2">
                        <span class="text-gray-800 font-semibold">{{ article.hasSummary ? 'Rezumat:' : 'Fară rezumat' }}</span>
                        {{ article.summaryText }}
                    </p>
                </Link>

                <div class="gap-2 space-y-2 text-xs">
                    <div class="flex flex-1 max-md:flex-col max-md:space-y-1 md:gap-x-2">
                        <MentionedParties
                            v-if="article.partyTags"
                            :partyTags="article.partyTags"
                        />

                        <PoliticalOrientation v-if="article" :politicalLeaning="article.politicalLeaningData" />

                        <div
                            class="border border-gray-100 bg-white px-2 py-1 rounded inline-flex max-md:grid max-md:grid-cols-2 items-center gap-2 md:min-h-[30px] max-h-[35px]">
                            <Link
                                :aria-label="article.title"
                                :href="`/articol/${article.slug}`"
                                class="hover:scale-105 hover:transition font-semibold text-main"
                            >
                                Vezi analiza completă
                            </Link>
                            <span class="text-gray-400 max-md:hidden">•</span>
                            <div class="max:md:-order-1">
                                <Link
                                    :aria-label="article.title"
                                    :href="`/articol/${article.slug}`"
                                    class="text-gray-600 hover:text-blue-600 font-semibold"
                                >
                                    {{ article.similarArticlesCount || 0 }}
                                </Link>
                                <span class="text-gray-400 px-1 max-md:hidden">•</span>
                                <a
                                    :href="article.url"
                                    class="text-gray-800 inline-flex items-center gap-1 hover:text-blue-600 font-semibold"
                                    target="_blank"
                                >
                                    Vezi sursa
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <!-- Desktop Image -->
            <Link
                v-if="article.image"
                :href="`/articol/${article.slug}`"
                class="max-md:hidden w-48 group relative overflow-hidden"
                :aria-label="article.title"
            >
                <div class="absolute inset-0 bg-black/5 group-hover:bg-black/10 transition-colors duration-300"></div>
                <LazyImage :src="article.image" :alt="article.title" height="200" width="200" ></LazyImage>
            </Link>
        </div>
    </div>
</template>

<script>

import MentionedParties from "./MentionedParties.vue";
import PoliticalOrientation from "./PoliticalOrientation.vue";
import { Link } from "@inertiajs/vue3"
import LazyImage from "@/Components/LazyImage.vue";

export default {
    props: {
        article: {
            type: Object,
            required: true, // Ensures this prop is always provided
        },
    },
    components: {
        LazyImage,
        Link,
        MentionedParties,
        PoliticalOrientation,
    },
};
</script>

