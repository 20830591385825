<template>
    <footer class="bg-surface text-gray-900 border-b border-gray-200">
        <div class="max-w-5xl mx-auto px-4 py-8 sm:px-6 lg:px-8">
            <!-- Top Section: Logo and Description -->
            <div class="flex flex-col items-center text-center mb-6">
                <img
                    :src="'/images/logo_webplogo.webp'"
                    alt="Afla pe cine votezi"
                    class="w-32 h-auto mb-4"
                    height="180"
                    width="180"
                    loading="lazy"
                />
                <p class="text-sm text-gray-900 mb-4">Știri politice din mai multe perspective.</p>
                <a
                    href="https://www.buymeacoffee.com/ifasconsult"
                    target="_blank"
                    class="bg-main text-xs text-white inline-flex items-center font-semibold py-2 px-4 rounded-md shadow-md hover:bg-main-2"
                >
                    <img
                        :src="'/images/bmc-logo.svg'"
                        alt="Buy me a coffee"
                        class="mr-2"
                        width="16"
                        height="16"
                        loading="lazy"
                    />
                    Cumpără-mi o cafea
                </a>
            </div>

            <!-- Navigation Links Section -->
            <div class="grid grid-cols-2 gap-6 text-center text-sm mb-6">
                <div>
                    <p class="font-semibold mb-2">Meniu</p>
                    <ul class="space-y-2">
                        <li><Link href="/">Știri</Link></li>
                        <li><Link href="/politicieni">Politicieni</Link></li>
                        <li><Link href="/publicatii">Publicații</Link></li>
                        <li><Link href="/despre">Despre</Link></li>
                        <li><Link href="/cum-functioneaza">Metodologie</Link></li>
                        <li><Link href="/contact">Contact</Link></li>
                    </ul>
                </div>
                <div>
                    <p class="font-semibold mb-2">Companie</p>
                    <ul class="space-y-2">
                        <li><Link href="/intrebari-frecvente">Întrebări Frecvente</Link></li>
                        <li><Link href="/termeni-si-conditii">Termeni și condiții</Link></li>
                        <li><Link href="/politica-de-confidentialitate">Politica de confidențialitate</Link></li>
                    </ul>
                </div>
            </div>

            <!-- Social Media Links Section -->
            <div class="text-center mb-6">
                <p class="font-semibold mb-2">Urmărește-ne</p>
                <div class="flex justify-center space-x-6">
                    <a
                        href="https://www.facebook.com/aflapecinevotezi/"
                        target="_blank"
                        class="text-gray-900 hover:text-main-2 flex items-center"
                    >
                        <i class="fab fa-facebook-f mr-2"></i> Facebook
                    </a>
                    <a
                        href="https://www.instagram.com/aflapecinevotezi/"
                        target="_blank"
                        class="text-gray-900 hover:text-main-2 flex items-center"
                    >
                        <i class="fab fa-instagram mr-2"></i> Instagram
                    </a>
                </div>
            </div>

            <!-- Footer Bottom -->
            <div class="text-center">
                <p class="text-sm font-medium">
                    Copyright © 2024 IFAS CONSULT S.R.L. All rights reserved. Made with
                    <i class="fa fa-heart text-red-500"></i> by
                    <a href="https://ifasconsult.ro" target="_blank" class="text-main-2 hover:underline">IFAS Consult</a>
                </p>
            </div>
        </div>
    </footer>
</template>

<script>
import { Link } from "@inertiajs/vue3";

export default {
    components: {
        Link,
    },
    name: "Footer",
};
</script>

<style scoped>
/* Add custom styles if needed */
</style>
