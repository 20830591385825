<template>
    <div class="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 py-6">
        <div class="bg-white rounded-3xl shadow-lg overflow-hidden">
            <div class="p-6 prose max-w-none">
                <h1 class="text-3xl font-bold mb-6">Politica de Confidențialitate</h1>

                <div class="space-y-6">
                    <div>
                        <h2 class="text-xl font-semibold mb-2">1. Introducere</h2>
                        <p class="text-gray-700">Această Politică de Confidențialitate explică modul în care "Află pe cine votezi" colectează, utilizează și protejează informațiile personale ale utilizatorilor noștri. Ne angajăm să respectăm dreptul dvs. la confidențialitate și să ne conformăm Regulamentului General privind Protecția Datelor (GDPR).</p>
                    </div>

                    <div>
                        <h2 class="text-xl font-semibold mb-2">2. Informațiile pe care le colectăm</h2>
                        <p class="text-gray-700">Putem colecta următoarele tipuri de informații:</p>
                        <ul class="list-disc pl-5 text-gray-700">
                            <li>Informații de identificare (ex: nume, adresă de email)</li>
                            <li>Informații de utilizare a aplicației</li>
                            <li>Preferințe politice exprimate în cadrul aplicației</li>
                        </ul>
                    </div>

                    <div>
                        <h2 class="text-xl font-semibold mb-2">3. Cum utilizăm informațiile</h2>
                        <p class="text-gray-700">Utilizăm informațiile colectate pentru:</p>
                        <ul class="list-disc pl-5 text-gray-700">
                            <li>A vă oferi acces la funcționalitățile aplicației</li>
                            <li>A îmbunătăți și personaliza experiența utilizatorului</li>
                            <li>A efectua analize agregate pentru îmbunătățirea serviciului</li>
                            <li>A comunica cu dvs. despre actualizări sau modificări ale serviciului</li>
                        </ul>
                    </div>

                    <div>
                        <h2 class="text-xl font-semibold mb-2">4. Baza legală pentru prelucrare</h2>
                        <p class="text-gray-700">Prelucrăm datele dvs. personale pe baza următoarelor temeiuri legale:</p>
                        <ul class="list-disc pl-5 text-gray-700">
                            <li>Consimțământul dvs.</li>
                            <li>Necesitatea de a executa un contract cu dvs.</li>
                            <li>Interesele noastre legitime, atâta timp cât acestea nu vă încalcă drepturile și libertățile</li>
                        </ul>
                    </div>

                    <div>
                        <h2 class="text-xl font-semibold mb-2">5. Partajarea informațiilor</h2>
                        <p class="text-gray-700">Nu vindem sau închiriem informațiile dvs. personale către terți. Putem partaja informații cu:</p>
                        <ul class="list-disc pl-5 text-gray-700">
                            <li>Furnizori de servicii care ne ajută să operăm aplicația</li>
                            <li>Autorități publice, când suntem obligați legal să facem acest lucru</li>
                        </ul>
                    </div>

                    <div>
                        <h2 class="text-xl font-semibold mb-2">6. Drepturile dvs.</h2>
                        <p class="text-gray-700">Conform GDPR, aveți următoarele drepturi:</p>
                        <ul class="list-disc pl-5 text-gray-700">
                            <li>Dreptul de acces la datele dvs. personale</li>
                            <li>Dreptul la rectificarea datelor incorecte</li>
                            <li>Dreptul la ștergerea datelor ("dreptul de a fi uitat")</li>
                            <li>Dreptul la restricționarea prelucrării</li>
                            <li>Dreptul la portabilitatea datelor</li>
                            <li>Dreptul de a vă opune prelucrării</li>
                            <li>Dreptul de a nu face obiectul unei decizii bazate exclusiv pe prelucrarea automată</li>
                        </ul>
                    </div>

                    <div>
                        <h2 class="text-xl font-semibold mb-2">7. Securitatea datelor</h2>
                        <p class="text-gray-700">Implementăm măsuri de securitate tehnice și organizaționale adecvate pentru a proteja datele dvs. personale împotriva accesului neautorizat, modificării, dezvăluirii sau distrugerii accidentale sau ilegale.</p>
                    </div>

                    <div>
                        <h2 class="text-xl font-semibold mb-2">8. Păstrarea datelor</h2>
                        <p class="text-gray-700">Păstrăm datele dvs. personale doar atât timp cât este necesar pentru scopurile descrise în această politică sau conform cerințelor legale aplicabile.</p>
                    </div>

                    <div>
                        <h2 class="text-xl font-semibold mb-2">9. Modificări ale politicii de confidențialitate</h2>
                        <p class="text-gray-700">Ne rezervăm dreptul de a modifica această politică de confidențialitate în orice moment. Vă vom notifica despre orice modificări semnificative prin postarea noii politici pe site-ul nostru.</p>
                    </div>

                    <div>
                        <h2 class="text-xl font-semibold mb-2">10. Contact</h2>
                        <p class="text-gray-700">Pentru orice întrebări sau preocupări legate de această politică de confidențialitate sau de practicile noastre de protecție a datelor, vă rugăm să ne contactați la:</p>
                        <p class="text-gray-700">Email: <a href="mailto:gdpr@aflapecinevotezi.ro">gdpr@aflapecinevotezi.ro</a></p>
                    </div>
                </div>

                <div class="mt-8 bg-yellow-50 border-l-4 border-yellow-400 p-4 rounded-lg">
                    <p class="text-sm text-gray-700">Această politică de confidențialitate a fost actualizată ultima dată la 03.10.2024.</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
    components: {
    },
});
</script>

<style scoped>
/* Add any additional custom styles here */
</style>
