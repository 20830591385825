<template>
    <div class="word-cloud-container">
        <VueWordCloud
            :words="words"
            :fontSizeMapper="fontSizeMapper"
            :rotate="rotate"
            :fontFamily="'Arial, sans-serif'"
            :color="colorMapper"
        />
    </div>
</template>

<script>
import VueWordCloud from "vuewordcloud";

export default {
    name: "WordCloud",
    components: {
        VueWordCloud,
    },
    props: {
        wordData: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            // Default words, in case `wordData` is not provided
            words: this.wordData || [
                { text: "Vue", weight: 10 },
                { text: "Word", weight: 8 },
                { text: "Cloud", weight: 6 },
            ],
            colors: ["#22C55E", "#EF4444", "#3B82F6", "#F59E0B", "#A855F7"],
        };
    },
    methods: {
        // Map colors for the words
        colorMapper(word) {
            // Use a consistent or random color for each word
            const index = this.words.indexOf(word) % this.colors.length;
            return this.colors[index];
        },
        // Map font size based on weight
        fontSizeMapper(word) {
            return word.weight * 2;
        },
        // Optionally define rotation angles
        rotate() {
            const angles = [0, 45, -45]; // Customize rotation angles
            return angles[Math.floor(Math.random() * angles.length)];
        },
    },
};
</script>

<style>
.word-cloud-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}
</style>
