<template>
    <div class="max-w-5xl  mx-auto px-4 sm:px-6 lg:px-8 py-6">
        <div class="bg-white rounded-3xl shadow-lg overflow-hidden">
            <div class="p-6 prose max-w-none">
                <h1 class="text-3xl font-bold mb-6">Termeni și Condiții</h1>

                <div class="space-y-6">
                    <div>
                        <h2 class="text-xl font-semibold mb-2">1. Acceptarea Termenilor</h2>
                        <p class="text-gray-700">Prin utilizarea aplicației "Află pe cine votezi", acceptați să respectați acești Termeni și Condiții. Dacă nu sunteți de acord cu acești termeni, vă rugăm să nu utilizați aplicația noastră.</p>
                    </div>

                    <div>
                        <h2 class="text-xl font-semibold mb-2">2. Descrierea Serviciului</h2>
                        <p class="text-gray-700">"Află pe cine votezi" este o aplicație web care utilizează tehnologia AI pentru a analiza promisiunile și declarațiile partidelor politice. Scopul nostru este de a oferi o perspectivă obiectivă asupra discursului politic pentru a ajuta alegătorii să ia decizii informate.</p>
                    </div>

                    <div>
                        <h2 class="text-xl font-semibold mb-2">3. Utilizarea Aplicației</h2>
                        <p class="text-gray-700">Vă angajați să utilizați aplicația doar în scopuri legale și în conformitate cu acești termeni. Este interzisă utilizarea aplicației pentru a:</p>
                        <ul class="list-disc pl-5 text-gray-700">
                            <li>Încălca drepturile altor utilizatori sau terțe părți</li>
                            <li>Distribui informații false sau înșelătoare</li>
                            <li>Interfera cu funcționarea normală a aplicației</li>
                        </ul>
                    </div>

                    <div>
                        <h2 class="text-xl font-semibold mb-2">4. Proprietate Intelectuală</h2>
                        <p class="text-gray-700">Toate drepturile de proprietate intelectuală asociate cu aplicația "Află pe cine votezi", inclusiv dar nu limitat la software, design, logo-uri și conținut, sunt proprietatea noastră sau a licențiatorilor noștri.</p>
                    </div>

                    <div>
                        <h2 class="text-xl font-semibold mb-2">5. Limitarea Răspunderii</h2>
                        <p class="text-gray-700">Deși ne străduim să oferim informații precise, nu putem garanta acuratețea completă a analizelor noastre. Utilizați aplicația pe propriul risc și vă încurajăm să verificați întotdeauna informațiile din surse multiple.</p>
                    </div>

                    <div>
                        <h2 class="text-xl font-semibold mb-2">6. Modificări ale Termenilor</h2>
                        <p class="text-gray-700">Ne rezervăm dreptul de a modifica acești Termeni și Condiții în orice moment. Modificările vor intra în vigoare imediat după publicarea lor pe site. Continuarea utilizării aplicației după publicarea modificărilor constituie acceptarea acestora.</p>
                    </div>

                    <div>
                        <h2 class="text-xl font-semibold mb-2">7. Confidențialitate</h2>
                        <p class="text-gray-700">Utilizarea datelor dumneavoastră personale este guvernată de Politica noastră de Confidențialitate, care poate fi consultată pe site-ul nostru.</p>
                    </div>

                    <div>
                        <h2 class="text-xl font-semibold mb-2">8. Legea Aplicabilă</h2>
                        <p class="text-gray-700">Acești Termeni și Condiții sunt guvernați de legile României. Orice dispută legată de acești termeni va fi supusă jurisdicției exclusive a instanțelor din România.</p>
                    </div>
                </div>

                <div class="mt-8 bg-yellow-50 border-l-4 border-yellow-400 p-4 rounded-lg">
                    <p class="text-sm text-gray-700">Pentru orice întrebări sau clarificări legate de acești Termeni și Condiții, vă rugăm să ne contactați la <a href="mailto:contact@aflapecinevotezi.ro">contact@aflapecinevotezi.ro</a>.</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from "vue";
import AboutCard from "../Components/AboutCard.vue";
import { Link } from "@inertiajs/vue3";

export default defineComponent({
    components: {
        Link,
        AboutCard,
    },
});
</script>

<style scoped>
/* Add any additional custom styles here */
</style>
