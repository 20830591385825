<template>
    <amp-auto-ads type="adsense"
                  data-ad-client="ca-pub-4069186346097674">
    </amp-auto-ads>
    <div class=" max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <!-- Publication Cards -->
        <div v-if="!loading" class="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
            <div
                v-for="publication in publications"
                :key="publication.id"
                @click="navigateToPublication(publication.name)"
                class="cursor-pointer bg-white rounded-xl shadow-md hover:shadow-lg transition-shadow duration-300 overflow-hidden border border-gray-200"
            >
                <div class="p-6 flex flex-col h-full">
                    <div class="flex justify-center items-center">
                        <div class="flex flex-col items-center">
                            <img
                                :src="publication.logo"
                                :alt="publication.name"
                                class="h-10 flex-shrink-0 flex items-center justify-center text-2xl font-bold text-white"
                                loading="lazy"
                            />
                            <div class="inline-flex my-2">
                                <span class="text-sm text-main font-semibold mr-2">Află mai multe &rarr;</span>
                                <svg
                                    class="h-5 w-5 text-blue-600"
                                    fill="none"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path d="M9 5l7 7-7 7"></path>
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from "vue";
import { usePage, router } from "@inertiajs/vue3";

export default {
    setup() {
        const { props } = usePage();
        const publications = ref(props.publications || []);
        const seo = ref(props.seo || {});
        const loading = ref(false);


        const navigateToPublication = (name) => {
            router.visit(route("publication", name));
        };

        return {
            seo,
            publications,
            loading,
            navigateToPublication,
        };
    },
};
</script>
