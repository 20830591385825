<template>
    <img
        ref="imageRef"
        :src="currentSrc"
        :alt="alt"
        :width="width"
        :height="height"
        :class="imageClasses"
        fetchpriority="high"
    />
</template>

<script>
export default {
    name: 'LazyImage',

    props: {
        src: {
            type: String,
            required: true
        },
        alt: {
            type: String,
            default: ''
        },
        width: {
            type: [String, Number],
            default: 'auto'
        },
        height: {
            type: [String, Number],
            default: 'auto'
        },
        objectFit: {
            type: String,
            default: 'cover'
        },
        classStyles: {
            type: String,
            default: 'auto'
        }
    },

    data() {
        return {
            isLoaded: false,
            observer: null,
            placeholderSrc: '/api/placeholder/400/320'
        }
    },

    computed: {
        currentSrc() {
            return this.isLoaded ? this.src : null
        },

        imageClasses() {
            return {
                'w-full h-full transition-opacity duration-300': true,
                'opacity-0': !this.isLoaded,
                'opacity-100': this.isLoaded,
                [`object-${this.objectFit}`]: true,
                [`${this.classStyles}`]: true,
            }
        }
    },

    methods: {
        loadImage() {
            const img = new Image()
            img.src = this.src
            img.onload = () => {
                this.isLoaded = true
            }
            img.onerror = () => {
                console.error(`Failed to load image: ${this.src}`)
                this.isLoaded = false
            }
        },

        setupObserver() {
            const options = {
                root: null,
                rootMargin: '50px',
                threshold: 0.1
            }

            this.observer = new IntersectionObserver(entries => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        this.loadImage()
                        this.observer.unobserve(entry.target)
                    }
                })
            }, options)

            if (this.$refs.imageRef) {
                this.observer.observe(this.$refs.imageRef)
            }
        }
    },

    mounted() {
        if ('IntersectionObserver' in window) {
            this.setupObserver()
        } else {
            console.log('fallback')
            // Fallback for browsers that don't support IntersectionObserver
            this.loadImage()
        }
    },

    beforeUnmount() {
        if (this.observer) {
            this.observer.disconnect()
        }
    }
}
</script>
