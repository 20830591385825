<template>
    <div class="space-y-4">
        <div v-for="articleId in visibleArticles" :key="articleId" class="article-container">
            <ArticleCard v-if="articles[articleId]" :article="articles[articleId]" />
            <ArticleCardSkeleton v-else />
        </div>
    </div>
</template>

<script>
import {ref, onMounted} from 'vue';
import axios from 'axios';
import ArticleCard from './ArticleCard.vue';
import ArticleCardSkeleton from './ArticleCardSkeleton.vue';

export default {
    name: 'ArticleList',
    components: {
        ArticleCard,
        ArticleCardSkeleton
    },
    props: {
        visibleArticles: {
            type: Array,
            required: true
        }
    },
    setup(props) {
        const articles = ref({});
        const queue = ref([]);
        const isProcessing = ref(false);
        const CONCURRENT_REQUESTS = 3; // Number of concurrent requests

        const fetchArticle = async (articleId) => {
            try {
                const response = await axios.get(`/api/article/id/${articleId}`, {
                    timeout: 5000,
                    headers: {
                        'Accept': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest'
                    }
                });

                if (response.data) {
                    articles.value[articleId] = response.data;
                }
            } catch (error) {
                console.error(`Failed to fetch article ${articleId}:`, error);
            }
        };

        const processQueue = async () => {
            if (isProcessing.value || queue.value.length === 0) return;

            isProcessing.value = true;
            const batch = queue.value.splice(0, CONCURRENT_REQUESTS);

            try {
                await Promise.all(batch.map(articleId => fetchArticle(articleId)));
            } catch (error) {
                console.error('Error processing batch:', error);
            }

            isProcessing.value = false;

            // If there are more items in the queue, process them after a small delay
            if (queue.value.length > 0) {
                setTimeout(() => processQueue(), 500); // 500ms delay between batches
            }
        };

        const addToQueue = (articleIds) => {
            queue.value = queue.value.concat(articleIds);
            processQueue();
        };

        onMounted(() => {
            if (props.visibleArticles.length > 0) {
                addToQueue(props.visibleArticles);
            }
        });

        return {
            articles
        };
    }
};
</script>
