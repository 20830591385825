<script setup>
import InputError from '@/Components/InputError.vue';
import InputLabel from '@/Components/InputLabel.vue';
import PrimaryButton from '@/Components/PrimaryButton.vue';
import TextInput from '@/Components/TextInput.vue';
import {Head, useForm} from '@inertiajs/vue3';

defineProps({
    status: {
        type: String,
    },
});

const form = useForm({
    email: '',
});

const submit = () => {
    form.post(route('password.email'));
};
</script>

<template>
    <div class="py-10 flex items-center justify-center">
        <div class="w-full max-w-4xl px-4 sm:px-6 lg:px-8">

            <div v-if="status" class="mb-4 text-sm font-medium text-green-600">
                {{ status }}
            </div>

            <form @submit.prevent="submit"
                  class="prose-sm bg-white p-6 sm:p-8 lg:p-10 rounded-xl shadow-lg max-w-md mx-auto grid gap-6">
                <div>
                    <div class="mb-4 text-sm text-gray-600">
                        Ai uitat parola? Nicio problemă. Doar spune-ne adresa ta de email și îți vom trimite un link de resetare a parolei care îți va permite să alegi una nouă.
                    </div>

                    <InputLabel for="email" value="Adresă de email"/>

                    <TextInput
                        id="email"
                        type="email"
                        class="mt-1 block w-full"
                        v-model="form.email"
                        required
                        autofocus
                        autocomplete="username"
                    />

                    <InputError class="mt-2" :message="form.errors.email"/>
                </div>

                <div class="mt-4 flex items-center justify-end">
                    <PrimaryButton
                        :class="{ 'opacity-25': form.processing }"
                        :disabled="form.processing"
                    >
                        Trimite link-ul de resetare a parolei
                    </PrimaryButton>
                </div>
            </form>

        </div>
    </div>
</template>
