<template>
    <div class="flex items-center justify-center p-4 text-gray-600">
        <div class="flex items-center gap-3">
            <div class="relative">
                <div class="animate-spin rounded-full h-8 w-8 border-2 border-gray-200" />
                <div
                    class="absolute top-0 left-0 animate-[spin_0.8s_linear_infinite_reverse] rounded-full h-8 w-8 border-2 border-transparent border-t-main"
                />
            </div>
            <p class="font-medium">
                Timp estimat: {{ timeLeft }} secunde
            </p>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            timeLeft: 10.0,
            timer: null
        }
    },
    mounted() {
        this.timer = setInterval(() => {
            if (this.timeLeft <= 0) {
                clearInterval(this.timer)
                this.timeLeft = 0
            } else {
                this.timeLeft = parseFloat((this.timeLeft - 0.1).toFixed(1))
            }
        }, 100)
    },
    beforeUnmount() {
        clearInterval(this.timer)
    }
}
</script>
