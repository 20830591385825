<template>
    <div class="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 py-6">
        <div class="bg-white rounded-3xl shadow-lg overflow-hidden">
            <div class="p-6 prose max-w-none">
                <h1 class="text-3xl font-bold mb-6">Întrebări Frecvente (FAQ)</h1>

                <div class="space-y-6">
                    <div>
                        <h2 class="text-xl font-semibold mb-2">1. Ce este "Află pe cine votezi"?</h2>
                        <p class="text-gray-700">"Află pe cine votezi" este o aplicație web care utilizează tehnologia AI pentru a analiza promisiunile și declarațiile partidelor politice. Scopul nostru este de a oferi alegătorilor o perspectivă obiectivă asupra discursului politic pentru a lua decizii informate.</p>
                    </div>

                    <div>
                        <h2 class="text-xl font-semibold mb-2">2. Cum funcționează aplicația?</h2>
                        <p class="text-gray-700">Aplicația noastră utilizează inteligența artificială și analiza avansată de date pentru a:</p>
                        <ul class="list-disc pl-5 text-gray-700">
                            <li>Colecta și procesa declarații politice din diverse surse</li>
                            <li>Analiza obiectivitatea și subiectivitatea conținutului</li>
                            <li>Identifica orientarea politică a articolelor</li>
                            <li>Prezenta informațiile într-un format ușor de înțeles</li>
                        </ul>
                    </div>

                    <div>
                        <h2 class="text-xl font-semibold mb-2">3. Care sunt valorile fundamentale ale aplicației?</h2>
                        <p class="text-gray-700">Valorile noastre fundamentale sunt:</p>
                        <ul class="list-disc pl-5 text-gray-700">
                            <li><strong>Transparență:</strong> Ne angajăm la o prezentare deschisă și imparțială a datelor politice cu ajutorul tehnologiei AI.</li>
                            <li><strong>Împuternicire:</strong> Credem în împuternicirea alegătorilor cu cunoștințe pentru a susține principiile democratice.</li>
                            <li><strong>Inovație:</strong> Inima aplicației noastre este tehnologia inovatoare proiectată pentru a simplifica lumea complexă a informațiilor politice.</li>
                        </ul>
                    </div>

                    <div>
                        <h2 class="text-xl font-semibold mb-2">4. Cât de precisă este analiza AI?</h2>
                        <p class="text-gray-700">În timp ce ne străduim să oferim analize cât mai precise, este important să înțelegem că tehnologia AI are limitările sale. Vă încurajăm să utilizați aplicația noastră ca un punct de plecare pentru o cercetare mai aprofundată și să verificați întotdeauna informațiile din surse multiple.</p>
                    </div>

                    <div>
                        <h2 class="text-xl font-semibold mb-2">5. Cum pot afla mai multe despre metodologia voastră?</h2>
                        <p class="text-gray-700">Puteți afla mai multe despre metodologia noastră accesând secțiunea "Cum funcționează" din aplicația noastră sau făcând clic pe butonul "Află mai multe despre metodologia noastră" din pagina principală.</p>
                    </div>

                    <!-- Add more FAQ items here -->

                </div>

                <div class="mt-8 bg-yellow-50 border-l-4 border-yellow-400 p-4 rounded-lg">
                    <p class="text-sm text-gray-700">Pentru mai multe întrebări sau clarificări, vă rugăm să ne contactați la <a
                        href="mailto:contact@aflapecinevotezi.ro">contact@aflapecinevotezi.ro</a>.</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from "vue";
import AboutCard from "../Components/AboutCard.vue";
import { Link } from "@inertiajs/vue3";

export default defineComponent({
    components: {
        Link,
        AboutCard,
    },
});
</script>

<style scoped>
/* Add any additional custom styles here */
</style>
