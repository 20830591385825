<template>
    <div class="max-w-5xl mx-auto px-6 sm:px-8 lg:px-10 py-12">
        <!-- Header Section -->
        <div class="mb-8">
            <h1 class="text-3xl font-bold text-gray-900 mb-4">Despre Află pe cine votezi</h1>
        </div>

        <div class="flex flex-wrap gap-8">
            <!-- Left Column -->
            <div class="space-y-8 max-w-5xl">
                <AboutCard title="Introducere">
                    <p class="text-gray-600 leading-relaxed">
                        Aplicația noastră este concepută pentru a împuternici alegătorii, oferind o perspectivă
                        transparentă și analitică asupra informațiilor politice. Într-o lume în care informațiile
                        sunt abundente, dar nu întotdeauna exacte, ne propunem să fim un far al adevărului în
                        ceața discursului politic.
                    </p>
                </AboutCard>

                <AboutCard title="Misiune">
                    <p class="text-gray-600 leading-relaxed">
                        Misiunea noastră este de a îmbunătăți procesul democratic, oferind o platformă accesibilă
                        unde alegătorii pot lua decizii informate. Utilizăm analiza avansată de date și tehnologie
                        ușor de utilizat pentru a descompune informații politice complexe în perspective ușor de
                        înțeles.
                    </p>
                </AboutCard>

                <AboutCard title="Viziune">
                    <p class="text-gray-600 leading-relaxed">
                        Ne imaginăm un viitor în care fiecare alegător, echipat cu informații de încredere, poate
                        participa la procesul electoral cu certitudine. Aplicația noastră este mai mult decât un
                        instrument; este o mișcare către un angajament politic informat, responsabil și transparent.
                    </p>
                </AboutCard>

                <AboutCard title="Cum funcționează">
                    <p class="text-gray-600 leading-relaxed mb-4">
                        Aplicația noastră utilizează inteligența artificială și analiza avansată de date pentru a:
                    </p>
                    <ul class="list-disc list-inside space-y-2 text-gray-600">
                        <li>Colecta și procesa declarații politice din diverse surse</li>
                        <li>Analiza obiectivitatea și subiectivitatea conținutului</li>
                        <li>Identifica orientarea politică a articolelor</li>
                        <li>Analiza sursele de știri pe baza datelor adunate</li>
                        <li>Prezenta informațiile într-un format ușor de înțeles</li>
                    </ul>
                </AboutCard>
                <AboutCard title="Despre">
                    <div class="space-y-6">
                        <p class="text-gray-600 leading-relaxed">
                            Află pe cine votezi este o aplicație web care folosește tehnologia AI pentru a analiza
                            promisiunile și declarațiile partidelor politice. Scopul nostru este de a oferi alegătorilor
                            o perspectivă obiectivă asupra discursului politic pentru a lua decizii informate.
                        </p>
                        <Link
                            href="/cum-functioneaza"
                            class="inline-block w-full md:w-auto bg-main hover:bg-main/90 text-white font-semibold py-3 px-6 rounded-md shadow-md transition-colors duration-200 text-center"
                        >
                            Află mai multe despre metodologia noastră
                        </Link>
                    </div>
                </AboutCard>

                <AboutCard title="Valori">
                    <p class="text-gray-600 font-medium mb-4">Transparență, Împuternicire, Inovație</p>
                    <ul class="space-y-4 text-gray-600">
                        <li class="flex items-start gap-3">
                            <svg class="w-6 h-6 text-main flex-shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                            <span>
                <strong class="font-medium">Transparență</strong> - Ne angajăm la o prezentare deschisă și imparțială a datelor politice cu ajutorul tehnologiei AI.
              </span>
                        </li>
                        <li class="flex items-start gap-3">
                            <svg class="w-6 h-6 text-main flex-shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                            <span>
                <strong class="font-medium">Împuternicire</strong> - Credem în împuternicirea alegătorilor cu cunoștințe pentru a susține principiile democratice.
              </span>
                        </li>
                        <li class="flex items-start gap-3">
                            <svg class="w-6 h-6 text-main flex-shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                            <span>
                <strong class="font-medium">Inovație</strong> - Inima aplicației noastre este tehnologia inovatoare proiectată pentru a simplifica lumea complexă a informațiilor politice.
              </span>
                        </li>
                    </ul>
                </AboutCard>

                <div class="bg-amber-50 border-l-4 border-amber-400 rounded-xl p-6 shadow-md">
                    <p class="text-amber-800 text-sm">
                        <strong class="font-medium">Notă:</strong> În timp ce ne străduim să oferim analize cât mai precise,
                        tehnologia AI are limitările sale. Vă încurajăm să utilizați aplicația noastră ca un punct de
                        plecare pentru o cercetare mai aprofundată și să verificați întotdeauna informațiile din surse multiple.
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from "vue";
import AboutCard from "../Components/AboutCard.vue";
import { Link } from "@inertiajs/vue3";

export default defineComponent({
    components: {
        Link,
        AboutCard,
    },
});
</script>

<style scoped>
/* Add any additional custom styles here */
</style>
