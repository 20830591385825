<template>
    <div class="flex items-center justify-center p-6 space-x-3">
        <div class="relative">
            <div class="animate-spin rounded-full h-10 w-10 border-4 border-gray-200"></div>
            <div
                class="absolute top-0 left-0 animate-[spin_0.8s_linear_infinite_reverse] rounded-full h-10 w-10 border-4 border-transparent border-t-main"
            ></div>
        </div>
        <p class="text-sm font-medium text-gray-600">
            Se încarcă<span class="animate-[ellipsis_1.5s_infinite]">...</span>
        </p>
    </div>
</template>
