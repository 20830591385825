<template>
    <div>
        <ArticleCard v-if="article && !error" :article="article" />
        <ArticleCardSkeleton v-if="!article && !error" />
        <div v-if="error" class="bg-red-50 border border-red-200 rounded-lg p-4">
            <p class="text-red-600 text-sm">{{ error }}</p>
            <button
                @click="retryFetch"
                class="mt-2 text-xs text-red-600 hover:text-red-800 underline"
            >
                Încearcă din nou
            </button>
        </div>
    </div>
</template>

<script>
import { ref, onMounted, onUnmounted } from 'vue';
import axios from 'axios';
import ArticleCard from './ArticleCard.vue';
import ArticleCardSkeleton from './ArticleCardSkeleton.vue';

export default {
    name: 'LazyArticleCard',
    components: {
        ArticleCard,
        ArticleCardSkeleton
    },
    props: {
        articleId: {
            type: [Number, String],
            required: true
        }
    },
    setup(props) {
        const article = ref(null);
        const error = ref(null);
        let fetchController = null;

        const fetchArticle = async () => {
            if (fetchController) {
                fetchController.abort();
            }

            fetchController = new AbortController();
            error.value = null;

            try {
                const response = await axios.get(`/api/article/id/${props.articleId}`, {
                    signal: fetchController.signal,
                    timeout: 5000,
                    headers: {
                        'Accept': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest'
                    }
                });

                if (response.data) {
                    article.value = response.data;
                } else {
                    throw new Error('Articolul nu a putut fi încărcat');
                }
            } catch (err) {
                if (axios.isCancel(err)) {
                    console.log('Request cancelled:', err.message);
                    return;
                }

                if (err.response) {
                    error.value = err.response.status === 404
                        ? 'Articolul nu a fost găsit'
                        : `Eroare la încărcarea articolului (${err.response.status})`;
                } else if (err.request) {
                    error.value = 'Nu s-a putut stabili conexiunea cu serverul';
                } else {
                    error.value = 'A apărut o eroare la încărcarea articolului';
                }
                console.error(`Failed to load article ${props.articleId}:`, err);
            } finally {
                fetchController = null;
            }
        };

        const retryFetch = () => {
            error.value = null;
            article.value = null;
            fetchArticle();
        };

        onMounted(() => {
            fetchArticle();
        });

        onUnmounted(() => {
            if (fetchController) {
                fetchController.abort();
                fetchController = null;
            }
        });

        return {
            article,
            error,
            retryFetch
        };
    }
};
</script>
