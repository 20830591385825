<template>
    <div class="border border-gray-100 p-1 px-2 rounded-lg text-sm">
        <div class="flex items-center justify-between mb-1.5">
            <div class="flex items-center gap-2">
                <span class="font-medium text-gray-700">Persoane</span>
                <span class="text-gray-400 text-xs">•</span>
                <span class="text-gray-500 text-xs">Menționate</span>
            </div>
        </div>
        <div class="flex flex-col">
            <div class="flex flex-wrap gap-1">
            <!-- First 10 tags (always visible) -->
            <div v-for="(tag, index) in visibleTags" :key="tag.id">
      <span class="px-1.5 py-0.5 bg-gray-100 rounded text-xs hover:bg-gray-200 transition-colors">
        {{ tag.name }}
      </span>
            </div>

            <!-- Remaining tags (shown when expanded) -->
            <div
                v-if="remainingTags.length > 0"
                v-show="showAllPersons"
                class="flex flex-wrap gap-1"
            >
                <div v-for="(tag, index) in remainingTags" :key="tag.id">
        <span class="px-1.5 py-0.5 bg-gray-100 rounded text-xs hover:bg-gray-200 transition-colors">
          {{ tag.name }}
        </span>
                </div>
            </div>
            </div>

            <!-- Toggle Button -->
            <button
                v-if="remainingTags.length > 0"
                @click="showAllPersons = !showAllPersons"
                class="text-main h-[25px] w-1/3 text-nowrap mt-2 px-4 py-1 text-xs font-medium rounded-lg border border-main hover:bg-main hover:text-white transition-all duration-200 shadow-sm"
            >
                {{ showAllPersons ? "Ascunde" : "Arată mai multe" }}
            </button>

        </div>
    </div>
</template>

<script>
export default {
    props: {
        tags: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            showAllPersons: false,
        };
    },
    computed: {
        visibleTags() {
            return this.tags.slice(0, 10);
        },
        remainingTags() {
            return this.tags.slice(10);
        },
    },
};
</script>

<style scoped>
/* Add any specific styles if needed */
</style>
