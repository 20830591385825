<template>
    <div v-if="publication && publication.owner" class="border border-gray-100 bg-white p-2 rounded text-sm">
        <div class="flex items-center gap-2 mb-1.5">
            <span class="font-medium text-gray-700">Proprietate</span>
            <span class="text-gray-400 text-xs">•</span>
            <span class="text-gray-500 text-xs">Afiliere</span>
        </div>
        <PublicationOwnershipBadge :publication="publication" />
    </div>
</template>

<script>

import PublicationOwnershipBadge from "./PublicationOwnershipBadge.vue";

export default {
    props: {
        publication: {
            type: Object,
            required: true,
        },
    },
    components: {
        PublicationOwnershipBadge,
    },
};
</script>
