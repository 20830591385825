<script setup>
import InputError from '@/Components/InputError.vue';
import InputLabel from '@/Components/InputLabel.vue';
import PrimaryButton from '@/Components/PrimaryButton.vue';
import TextInput from '@/Components/TextInput.vue';
import { Link, useForm } from '@inertiajs/vue3';

const form = useForm({
    name: '',
    email: '',
    password: '',
    password_confirmation: '',
});

const submit = () => {
    form.post(route('register'), {
        onFinish: () => form.reset('password', 'password_confirmation'),
    });
};

// Redirect routes for social login
const googleLoginRoute = route('social-login', { provider: 'google' });
const facebookLoginRoute = route('social-login', { provider: 'facebook' });
</script>

<template>
    <div class="py-10 flex items-center justify-center">
        <div class="w-full max-w-4xl px-4 sm:px-6 lg:px-8">
            <form
                @submit.prevent="submit"
                class="prose-sm bg-white p-6 sm:p-8 lg:p-10 rounded-xl shadow-lg max-w-md mx-auto grid gap-6"
            >
                <h1 class="text-center text-2xl font-extrabold text-main">Creează un cont</h1>

                <div class="grid gap-2">
                    <InputLabel for="name" value="Nume complet" />
                    <TextInput
                        id="name"
                        type="text"
                        class="mt-1 block w-full border-gray-300 rounded-md focus:border-main focus:ring-main"
                        v-model="form.name"
                        required
                        autofocus
                        autocomplete="name"
                    />
                    <InputError class="error mt-1" :message="form.errors.name" />
                </div>

                <div class="grid gap-2">
                    <InputLabel for="email" value="Adresă de email" />
                    <TextInput
                        id="email"
                        type="email"
                        class="mt-1 block w-full border-gray-300 rounded-md focus:border-main focus:ring-main"
                        v-model="form.email"
                        required
                        autocomplete="username"
                    />
                    <InputError class="error mt-1" :message="form.errors.email" />
                </div>

                <div class="grid gap-2">
                    <InputLabel for="password" value="Parolă" />
                    <TextInput
                        id="password"
                        type="password"
                        class="mt-1 block w-full border-gray-300 rounded-md focus:border-main focus:ring-main"
                        v-model="form.password"
                        required
                        autocomplete="new-password"
                    />
                    <InputError class="error mt-1" :message="form.errors.password" />
                </div>

                <div class="grid gap-2">
                    <InputLabel for="password_confirmation" value="Confirmă parola" />
                    <TextInput
                        id="password_confirmation"
                        type="password"
                        class="mt-1 block w-full border-gray-300 rounded-md focus:border-main focus:ring-main"
                        v-model="form.password_confirmation"
                        required
                        autocomplete="new-password"
                    />
                    <InputError class="error mt-1" :message="form.errors.password_confirmation" />
                </div>

                <div class="flex flex-col-reverse sm:flex-row sm:justify-between items-center mt-4">
                    <Link
                        :href="route('login')"
                        class="text-sm font-medium text-main hover:underline mt-4 sm:mt-0"
                    >
                        Ai deja un cont? Autentifică-te
                    </Link>

                    <PrimaryButton
                        class="bg-main text-white hover:scale-105 hover:bg-main/90 px-4 py-2 rounded-md transition ease-in-out duration-200"
                        :disabled="form.processing"
                    >
                        Creează cont
                    </PrimaryButton>
                </div>

                <!-- Separator -->
                <div class="flex items-center gap-2 mt-6">
                    <div class="flex-grow border-t border-gray-300"></div>
                    <span class="text-sm text-gray-500">sau conectează-te cu</span>
                    <div class="flex-grow border-t border-gray-300"></div>
                </div>

                <!-- Social Login Buttons -->
                <div class="grid gap-4 mt-4">
                    <a
                        :href="googleLoginRoute"
                        class="bg-white border border-gray-300 text-main/90 hover:bg-main/10 rounded-md px-4 py-2 flex items-center justify-center gap-2 transition"
                    >
                        <img
                            src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg"
                            alt="Google logo"
                            class="w-5 h-5"
                        />
                        <span>Conectează-te cu Google</span>
                    </a>

<!--                    <a-->
<!--                        :href="facebookLoginRoute"-->
<!--                        class="bg-blue-600 text-white hover:bg-blue-700 rounded-md px-4 py-2 flex items-center justify-center gap-2 transition"-->
<!--                    >-->
<!--                        <img-->
<!--                            src="https://upload.wikimedia.org/wikipedia/commons/5/51/Facebook_f_logo_%282019%29.svg"-->
<!--                            alt="Facebook logo"-->
<!--                            class="w-5 h-5"-->
<!--                        />-->
<!--                        <span>Conectează-te cu Facebook</span>-->
<!--                    </a>-->
                </div>
            </form>
        </div>
    </div>
</template>
