<template>
    <div class="bg-white rounded-lg p-4 shadow animate-pulse">
        <!-- Publication Logo Skeleton -->
        <div class="flex items-center mb-4">
            <div class="w-[25px] h-[25px] bg-gray-200 rounded"></div>
            <div class="ml-2 h-4 bg-gray-200 rounded w-24"></div>
        </div>

        <!-- Title Skeleton -->
        <div class="space-y-3">
            <div class="h-4 bg-gray-200 rounded w-3/4"></div>
            <div class="h-4 bg-gray-200 rounded w-1/2"></div>
        </div>

        <!-- Summary Skeleton -->
        <div class="mt-4 space-y-2">
            <div class="h-3 bg-gray-200 rounded w-full"></div>
            <div class="h-3 bg-gray-200 rounded w-5/6"></div>
            <div class="h-3 bg-gray-200 rounded w-4/6"></div>
        </div>

        <!-- Footer Skeleton -->
        <div class="mt-4 flex justify-between">
            <div class="h-4 bg-gray-200 rounded w-20"></div>
            <div class="h-4 bg-gray-200 rounded w-24"></div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ArticleCardSkeleton'
}
</script>
