<template>
    <div class="py-2">
        <!-- Segmented Control -->
        <div class="inline-flex gap-x-1 h-[30px] max-md:h-[55px] items-center p-1 rounded-lg  shadow-sm border border-surface bg-white">
            <button
                v-for="option in options"
                :key="option.value"
                @click="selectedOption = option.value"
                type="button"
                class="font-medium text-xs h-[25px] max-md:h-[50px] rounded-md transition-all duration-200 px-4 py-"
                :class="[
                    selectedOption === option.value ? option.activeColor : option.inactiveColor,
                    option.hoverColor,
                    'hover:opacity-90',
                ]"
                aria-current="page"
            >
                <i v-if="option.icon" :class="`fa fa-${option.icon} mr-1`"></i>
                {{ option.label }}
            </button>
        </div>

        <!-- Summaries -->
        <div class="mt-4 space-y-4">
            <div
                v-for="option in options"
                :key="option.value"
                v-show="selectedOption === option.value"
                class="summary list-disc list-inside space-y-1 text-gray-800 border border-gray-100 bg-white p-2 rounded-md text-sm"
            >
                <p class="text-sm" v-if="getSummary(option.value)">
                    {{ getSummary(option.value) }}
                </p>
                <p v-else class="text-gray-500 italic">
                    Nu există un rezumat disponibil pentru această opțiune.
                </p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        summaryText: {
            type: String,
            default: null,
        },
        rightSummary: {
            type: String,
            default: null,
        },
        leftSummary: {
            type: String,
            default: null,
        },
        centerSummary: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            selectedOption: "summaryText", // Default selected option
            options: [
                {
                    value: "summaryText",
                    label: "Rezumat propriu",
                    icon: "book",
                    activeColor: "bg-blue-600 text-white",
                    inactiveColor: "bg-blue-100 text-blue-700",
                    hoverColor: "hover:bg-blue-700 hover:text-white",
                },
                {
                    value: "leftSummary",
                    label: "Stânga",
                    icon: "arrow-left",
                    activeColor: "bg-red-600 text-white",
                    inactiveColor: "bg-red-100 text-red-700",
                    hoverColor: "hover:bg-red-700 hover:text-white",
                },
                {
                    value: "centerSummary",
                    label: "Centru",
                    icon: "align-center",
                    activeColor: "bg-gray-600 text-white",
                    inactiveColor: "bg-gray-100 text-gray-800",
                    hoverColor: "hover:bg-gray-700 hover:text-white",
                },
                {
                    value: "rightSummary",
                    label: "Dreapta",
                    icon: "arrow-right",
                    activeColor: "bg-blue-600 text-white",
                    inactiveColor: "bg-blue-100 text-blue-700",
                    hoverColor: "hover:bg-blue-700 hover:text-white",
                },
            ],
        };
    },
    methods: {
        getSummary(option) {
            // Dynamically return the summary based on the prop
            return this[option];
        },
    },
};
</script>

<style scoped>
/* Add custom styles if necessary */
</style>
