<script setup>
import { useForm } from '@inertiajs/vue3';
import {useToast} from 'vue-toast-notification';

const form = useForm({
    first_name: '',
    last_name: '',
    email: '',
    phone_number: '',
    message: '',
});

const toast = useToast();

function handleSubmit() {
    form.post(route('contact-me'), {
        onSuccess: () => {
            // Show success notification or reset the form
            form.reset(); // Reset the form fields
            toast.success('Mesajul a fost trimis cu succes!', {
                duration: 5000,
                dismissible: true,
            });
        },
        onError: () => {
            console.error('Error sending email:', form.errors);
            toast.error('A apărut o eroare. Verificați câmpurile și încercați din nou.', {
                duration: 5000,
                dismissible: true,
            });
        },
    });
}
</script>

<template>
    <div class="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 py-6">
        <div class="flex gap-6">
            <!-- About Card -->
            <div class="flex gap-6">
                <div
                    class="bg-white rounded-3xl shadow-lg border-t hover:shadow-xl transition-all ease-in-out duration-300 overflow-hidden"
                >
                    <div class="p-6 prose">
                        <h1>Contact</h1>
                        <p>
                            Dacă aveți întrebări sau feedback, nu ezitați să ne contactați la adresa de e-mail
                            <a href="mailto:contact@aflapecinevotezi.ro">contact@aflapecinevotezi.ro</a>.
                        </p>
                    </div>
                </div>
            </div>

            <!-- Form Card -->
            <div class="flex gap-6">
                <div
                    class="bg-white rounded-3xl shadow-lg border-t hover:shadow-xl transition-all ease-in-out duration-300 overflow-hidden"
                >
                    <div class="p-6 prose">
                        <h1>Formular</h1>
                        <p>
                            Dacă doriți să ne contactați folosind un formular, vă rugăm să completați următoarele
                            câmpuri:
                        </p>
                        <form @submit.prevent="handleSubmit">
                            <div>
                                <label class="block text-sm font-medium text-gray-700">Prenume</label>
                                <input
                                    v-model="form.first_name"
                                    type="text"
                                    name="first_name"
                                    class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-main focus:ring-main"
                                />
                                <span v-if="form.errors.first_name"
                                      class="text-red-500 text-sm">{{ $t(form.errors.first_name) }}</span>
                            </div>

                            <div>
                                <label class="block text-sm font-medium text-gray-700">Nume</label>
                                <input
                                    v-model="form.last_name"
                                    type="text"
                                    name="last_name"
                                    class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-main focus:ring-main"
                                />
                                <span v-if="form.errors.last_name" class="text-red-500 text-sm">{{
                                        $t(form.errors.last_name)
                                    }}</span>
                            </div>

                            <div>
                                <label class="block text-sm font-medium text-gray-700">Email</label>
                                <input
                                    v-model="form.email"
                                    type="email"
                                    name="email"
                                    class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-main focus:ring-main"
                                />
                                <span v-if="form.errors.email" class="text-red-500 text-sm">{{
                                        $t(form.errors.email)
                                    }}</span>
                            </div>

                            <div>
                                <label class="block text-sm font-medium text-gray-700">Nr. telefon</label>
                                <input
                                    v-model="form.phone_number"
                                    type="text"
                                    name="phone_number"
                                    class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-main focus:ring-main"
                                />
                                <span v-if="form.errors.phone_number"
                                      class="text-red-500 text-sm">{{ $t(form.errors.phone_number) }}</span>
                            </div>

                            <div>
                                <label class="block text-sm font-medium text-gray-700">Mesaj</label>
                                <textarea
                                    v-model="form.message"
                                    name="message"
                                    class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-main focus:ring-main"
                                ></textarea>
                                <span v-if="form.errors.message" class="text-red-500 text-sm">{{ $t(form.errors.message) }}</span>
                            </div>

                            <button
                                type="submit"
                                class="bg-main mt-2 hover:bg-main/90 w-full text-white font-bold py-2 px-4 rounded-xl focus:outline-none focus:shadow-outline"
                            >
                                {{ form.processing ? 'Se trimite...' : 'Trimite' }}
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
