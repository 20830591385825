<template>
    <div>
        <button @click="toggleDetails" class="mt-2 text-blue-500 hover:underline focus:outline-none text-sm">
            <span>{{ showDetails ? "Ascunde detalii" : "Arată detalii" }}</span>
        </button>
        <div v-if="showDetails" class="mt-4">
            <!-- Topic Classification -->
            <div v-if="hasTopicClassification" class="topic-classification">
                <h4 class="font-semibold mb-2">Clasificare categorie</h4>
                <div class="flex flex-wrap gap-2">
          <span
              v-for="topic in topicClassification"
              :key="topic.label"
              class="bg-gray-200 rounded-full px-2 py-1"
          >
            {{ topic.label }} ({{ topic.percentage }}%)
          </span>
                </div>
            </div>

            <!-- Sentiment Analysis -->
            <div class="sentiment-analysis">
                <h4 class="font-semibold mb-2">Analiza sentimentala</h4>
                <div class="flex items-center" :class="sentimentColor">
                    <span class="mr-2">{{ sentimentIcon }}</span>
                    <span class="capitalize">{{ finalSentiment }}</span>
                </div>
                <p class="text-xs">NLTK: {{ sentimentAnalysis.nltk_result || "N/A" }}</p>
                <p class="text-xs">TextBlob: {{ sentimentAnalysis.textblob_result || "N/A" }}</p>
                <p class="text-xs">Transformers: {{ sentimentAnalysis.transformers_result || "N/A" }}</p>
            </div>

            <!-- Most Common Words -->
            <div class="most-common-words">
                <h4 class="font-semibold mb-2">Cele mai folosite cuvinte</h4>
                <div class="flex flex-wrap gap-2">
          <span
              v-for="[word, count] in mostCommonWords"
              :key="word"
              class="bg-gray-200 rounded-full px-2 py-1 text-xs"
          >
            {{ word }} ({{ count }})
          </span>
                </div>
            </div>

            <!-- Mentioned Entities -->
            <div v-if="mentionedEntities.length" class="mentioned-entities mt-4">
                <h4 class="font-semibold mb-2">Entități menționate</h4>
                <div class="flex flex-wrap gap-2">
          <span
              v-for="(count, entity) in mentionedEntities"
              :key="entity"
              class="bg-blue-100 text-blue-800 rounded-full px-2 py-1 text-xs"
          >
            {{ entity }} ({{ count }})
          </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        analysis: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            showDetails: false,
        };
    },
    computed: {
        hasTopicClassification() {
            return this.analysis.topic_classification && this.analysis.topic_classification.length > 0;
        },
        topicClassification() {
            return this.analysis.topic_classification || [];
        },
        sentimentAnalysis() {
            return this.analysis.sentiment_analysis_json || {};
        },
        finalSentiment() {
            return this.sentimentAnalysis.final_sentiment || "Neutral";
        },
        sentimentIcon() {
            // Example: Adjust icons based on sentiment
            const icons = {
                Positive: "😊",
                Neutral: "😐",
                Negative: "😟",
            };
            return icons[this.finalSentiment] || "😐";
        },
        sentimentColor() {
            // Example: Adjust colors based on sentiment
            const colors = {
                Positive: "text-green-500",
                Neutral: "text-gray-500",
                Negative: "text-red-500",
            };
            return colors[this.finalSentiment] || "text-gray-500";
        },
        mostCommonWords() {
            return this.analysis.most_common_words || [];
        },
        mentionedEntities() {
            return this.analysis.mentioned_entities || {};
        },
    },
    methods: {
        toggleDetails() {
            this.showDetails = !this.showDetails;
        },
    },
};
</script>

<style scoped>
/* Add any scoped styles if necessary */
</style>
