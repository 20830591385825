<template>
    <div class="bg-white rounded-xl shadow-md hover:shadow-lg transition-all duration-300">
        <div class="p-6 space-y-4">
            <template v-if="title">
                <h2 class="text-2xl font-semibold text-gray-900">{{ title }}</h2>
            </template>
            <div class="prose prose-gray max-w-none">
                <slot />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            required: false, // Make this optional, similar to Blade's `@if($title)`
        },
    },
};
</script>

<style scoped>
/* Add custom styles here if needed */
</style>
